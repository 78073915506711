<template>
  <div>
    <b-form @submit.prevent="onSubmit">
      <b-card no-body class="mb-1">
        <b-card-header class="px-1 pt-1 pb-0 justify-content-end">
          <b-button
            size="sm"
            variant="flat-secondary"
            class="btn-icon rounded-circle"
            style="padding: 0.5rem 10px !important"
            @click="deleteContact(contactDto.id)"
          >
            حذف البريد
            <unicon name="trash-alt" width="18"></unicon>
          </b-button>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="12" lg="6">
              <EKInputText  :value="contactDto.name" label="الاسم" name="name" disabled />
              
              <EKInputText
                 :value="contactDto.subject"
                label="العنوان"
                name="title"
                disabled
              />
            </b-col>
            <b-col cols="12" lg="6">
                <EKInputText
                :value="contactDto.sendDate | formatDate"
                label="تاريخ الإرسال"
                name="date"
                disabled
              />
              <EKInputText
                 :value="contactDto.email"
                label="البريد الالكتروني"
                name="email"
                disabled
              />
              
            </b-col>
            <EKInputTextarea
              label="الرسالة"
              v-model="contactDto.description"
              name="description"
              rows="5"
              disabled
              class="w-100 px-1"
            />
          </b-row>
        </b-card-body>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header class="px-1 pt-1 pb-0">
          <h4>الرد على البريد</h4>
          <span v-if="!!contactDto.replyDate"
            >تاريخ الرد: {{ contactDto.replyDate | formatDate }}</span
          >
        </b-card-header>
        <b-card-body class="p-1 d-flex align-items-center">
          <b-form-group class="mb-0 w-100" label="نص الرد:">
            <div class="editor-container mt-2" v-if="!contactDto.replyDate">
              <quill-editor
                class="editor"
                ref="myTextEditor"
                :value="contactDto.reply"
                :options="editorOption"
                @change="onEditorChange"
              />
            </div>
            <p class="lead" v-else v-html="contactDto.reply"></p>
            <small class="text-danger mt-5" v-show="!contactDto.reply"
              >يرجى كتابة رد للرسالة</small
            >
          </b-form-group>
        </b-card-body>
        <b-card-footer v-if="!contactDto.replyDate" class="pt-0 border-0 pb-1 px-1">
          <b-button variant="primary" type="submit">إرسال الرد</b-button>
        </b-card-footer>
      </b-card>
    </b-form>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import hljs from "highlight.js";
import { required } from "@validations";
import { mapActions, mapState } from "vuex";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputTextarea from "@Ekcore/components/EK-forms/EK-input-textarea";
export default {
  components: {
    quillEditor,
    EKInputText,
    EKInputTextarea,
  },
  props: {
    id: String,
  },
  data: () => ({
    required,
    editorOption: {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote"],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ align: [] }],
        ],
        syntax: {
          highlight: (text) => hljs.highlightAuto(text).value,
        },
      },
      placeholder: "اكتب هنا",
    },
  }),
  computed: {
    ...mapState({
      contactDto: (state) => state.contact.contactDto,
    }),
  },
  created() {
    this.getContactDetails(this.id);
  },
  methods: {
    ...mapActions(["getContactDetails", "setReply", "deleteContact"]),
    onSubmit() {
      if (this.contactDto.reply) {
        this.setReply(this.contactDto);
      }
    },
    onEditorChange(value) {
      this.contactDto.reply = value.html;
    },
  },
};
</script>
